import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    mapboxDistJsUrl: String,
  };
  static targets = ["form", "alertAutoModal", "filtersCounterMobile", "filtersCounterDesktop"];

  connect() {
    window.hangReferenceStimulusController(this);

    this.searchPage = this.element.closest("turbo-frame#search_engine") != null;
    this.homePage = this.isHomePage();
    this.filtersCount = 0;
    document
      .querySelectorAll("[data-display-plugin-yp][data-display-plugin-yp-auto-open='true'].active")
      .forEach((element) => {
        if (element.offsetParent !== null) {
          element.click();
        }
      });

    document.querySelectorAll("input[name='price_max']").forEach((input) => {
      input.addEventListener("change", this.priceSliderChanged.bind(this));
    });

    this.changeFiltersCount();

    if (this.hasAlertAutoModalTarget) {
      if (this.isSearchPage()) {
        this.promiseAlertAutoModalDisplay();
      }
    }
  }

  promiseAlertAutoModalDisplay() {
    window.setTimeout(() => {
      this.alertAutoModalTarget.classList.add("active");
      this.displayAlertAutoModal();
    }, 60000);
  }

  displayAlertAutoModal() {
    var offerType = document.getElementById("search_show_short_term")?.checked ? "short_term_booking" : "subscription";

    if (offerType == "subscription") {
      this.alertAutoModalTarget.dataset.show = true;
    } else {
      this.alertAutoModalTarget.dataset.show = false;
    }
  }

  onChangeOfferTypeSubscriptionOrShortTermBooking(event) {
    var bsTarget = event.currentTarget.dataset.bsTarget;
    var offerType = bsTarget == "#bookings" ? "short_term_booking" : "subscription";

    this.handleAlertButton(offerType);
  }

  handleAlertButton(offerType) {
    if (offerType == "subscription") {
      document.querySelector("turbo-frame#filters_alerts_by_address")?.classList?.remove("d-none");
      if (this.hasAlertAutoModalTarget && this.alertAutoModalTarget.classList.contains("active"))
        this.alertAutoModalTarget.dataset.show = true;
    } else {
      document.querySelector("turbo-frame#filters_alerts_by_address")?.classList?.add("d-none");
      if (this.hasAlertAutoModalTarget && this.alertAutoModalTarget.classList.contains("active"))
        this.alertAutoModalTarget.dataset.show = false;
    }
  }

  isHomePage() {
    return window.location && window.location.pathname && window.location.pathname.startsWith("/");
  }

  isSearchPage() {
    return window.location && window.location.pathname && window.location.pathname.startsWith("/search");
  }

  closeAlertAuto() {
    this.alertAutoModalTarget.classList.remove("active");
    this.alertAutoModalTarget.dataset.show = false;
  }

  displayOverlayBackground() {
    if (document.body.classList.contains("modal-open")) return;
    document.body.classList.add("overlay-search-box");
  }

  hideOverlayBackground() {
    document.body.classList.remove("overlay-search-box");
  }

  displaySearchOverlaysLoading() {
    document
      .querySelector("#search-filters-and-subfilters")
      ?.componentsAddressAutocomplete?.displaySearchOverlaysLoading();
  }

  onPageClick(e) {
    if (!e.target) return;

    if (
      !e.target.classList?.contains("search-box--item") &&
      e.target.closest(".search-box--item") == null &&
      e.target.closest("[data-display-plugin-yp]") == null &&
      e.target.getAttribute("data-display-plugin-yp") == null &&
      e.target.closest(".search-plugin") == null &&
      e.target.closest(".dropdown-menu") == null &&
      e.target.closest(".flatpickr-calendar") == null
    ) {
      this.closeOtherYesparkCustomPluginsOpened(null);
      this.closeAllFlatpickrOpened();
    }

    const elementsOverlaying =
      document.querySelector(".search-box.active") != null || document.querySelector(".search-plugin.show") != null;

    if (!elementsOverlaying) {
      this.hideOverlayBackground();
    }
  }

  submitForm() {
    document.querySelector("#search-filters-and-subfilters")?.componentsAddressAutocomplete?.submitForm();
  }

  displayNextInput(e) {
    let shouldSubmitForm = true;

    // Find the first empty input that is also visible
    const shortTermBooking = Array.from(
      document.querySelectorAll("input[type='checkbox'][id='search_show_short_term']"),
    ).some((input) => input.checked);
    const locatedBtnPressed = document.querySelector("input[id='locate'][value='true']") != null;

    let nextEmptyInput;
    if (this.homePage && locatedBtnPressed && !shortTermBooking) {
      nextEmptyInput = false;
      shouldSubmitForm = true;
    } else {
      let emptyInputs = Array.from(document.forms["search-filters-and-subfilters"].elements).filter((formElement) => {
        if (formElement.id == "address") return false;
        if (formElement.type == "submit") return false;
        if (formElement.name == "order_by") return false;
        if (
          formElement.id == "vehicle_size" &&
          !Array.from(document.querySelectorAll("[name='vehicle_size']")).some((input) => input.checked)
        )
          return true;
        if (formElement.classList.contains("hidden")) {
          return false;
        }
        if (formElement.closest("#modalPluginFilters") != null) {
          return false;
        }
        if (formElement.id == null) return false;
        if (formElement.value.trim().length > 0) {
          return false;
        }
        if (
          shortTermBooking &&
          (formElement.id == "start_date" ||
            formElement.id == "end_date" ||
            formElement.id == "start_time" ||
            formElement.id == "end_time")
        ) {
          return true;
        }
        return formElement.offsetParent !== null;
      });
      if (emptyInputs && emptyInputs.length > 0) {
        const prioritiesInputsIds = ["start_date", "start_time", "end_date", "end_time", "vehicle_size"];
        const prioritiesInputsIdsLength = prioritiesInputsIds.length;

        const sortedEmptyInputs = emptyInputs.sort((a, b) => {
          const indexA = prioritiesInputsIds.indexOf(a.id);
          const indexB = prioritiesInputsIds.indexOf(b.id);

          const finalIndexA = indexA !== -1 ? indexA : prioritiesInputsIdsLength;
          const finalIndexB = indexB !== -1 ? indexB : prioritiesInputsIdsLength;

          return finalIndexA - finalIndexB;
        });

        nextEmptyInput = sortedEmptyInputs[0];
      } else {
        nextEmptyInput = false;
      }
    }

    if (nextEmptyInput) {
      if (
        nextEmptyInput.id == "start_date" ||
        nextEmptyInput.id == "start_time" ||
        nextEmptyInput.id == "end_date" ||
        nextEmptyInput.id == "end_time"
      ) {
        var pluginShortTermBooking = null;
        var pluginShortTermBookingBtn = null;
        if (nextEmptyInput.id == "start_date" || nextEmptyInput.id == "start_time") {
          pluginShortTermBooking = document.querySelector("#pluginShortTermBookingStart");
          pluginShortTermBookingBtn = document.querySelector(
            "button[data-display-plugin-yp='#pluginShortTermBookingStart']",
          );
        } else {
          pluginShortTermBooking = document.querySelector("#pluginShortTermBookingEnd");
          pluginShortTermBookingBtn = document.querySelector(
            "button[data-display-plugin-yp='#pluginShortTermBookingEnd']",
          );
        }
        if (pluginShortTermBooking && pluginShortTermBookingBtn) {
          shouldSubmitForm = false;
          if (!pluginShortTermBooking.classList.contains("show")) {
            this.displayOverlayBackground();
            this.openYesparkCustomPlugin(pluginShortTermBookingBtn);
          }
          setTimeout(() => {
            if (nextEmptyInput._flatpickr !== undefined) {
              nextEmptyInput._flatpickr.open();
            } else if (nextEmptyInput.dataset?.controller?.split()?.includes("selectpicker")) {
              nextEmptyInput.nextElementSibling.click();
            } else {
              nextEmptyInput.focus();
            }
          }, 0);
        }
      } else if (nextEmptyInput.id == "vehicle_size") {
        const pluginYourVehicle = document.querySelector("#pluginYourVehicle");
        const pluginYourVehicleBtn = document.querySelector("button[data-display-plugin-yp='#pluginYourVehicle']");
        if (pluginYourVehicle && pluginYourVehicleBtn) {
          shouldSubmitForm = false;
          if (!pluginYourVehicle.classList.contains("show")) {
            this.displayOverlayBackground();
            this.openYesparkCustomPlugin(pluginYourVehicleBtn);
          }
        }
      } else {
        nextEmptyInput.focus();
      }
    } else {
      this.closeAllYesparkCustomPluginsOpened();
      this.closeAllFlatpickrOpened();
    }

    if (shouldSubmitForm && (this.homePage || this.searchPage)) {
      this.triggerSearch(null);
    }
  }

  shortTermBookingInputChanged({ detail: { kind } }) {
    var pluginShortTermBookingBtn = null;
    var pluginShortTermBookingDate = null;
    var pluginShortTermBookingTime = null;
    if (kind == "start") {
      pluginShortTermBookingBtn = document.querySelector(
        "button[data-display-plugin-yp='#pluginShortTermBookingStart']",
      );
      pluginShortTermBookingDate = document.querySelector(
        "#pluginShortTermBookingStart input[type='hidden'][name='start_date']",
      );
      pluginShortTermBookingTime = document.querySelector("#pluginShortTermBookingStart select[name='start_time']");
    } else {
      pluginShortTermBookingBtn = document.querySelector("button[data-display-plugin-yp='#pluginShortTermBookingEnd']");
      pluginShortTermBookingDate = document.querySelector(
        "#pluginShortTermBookingEnd input[type='hidden'][name='end_date']",
      );
      pluginShortTermBookingTime = document.querySelector("#pluginShortTermBookingEnd select[name='end_time']");
    }

    if (pluginShortTermBookingBtn) {
      const defaultLabel = pluginShortTermBookingBtn.querySelector(".btn-short-term-booking-default-label");

      pluginShortTermBookingBtn
        .querySelectorAll(".btn-short-term-booking-selected-label")
        .forEach((elem) => elem.remove());

      var pluginShortTermBookingDateValue = pluginShortTermBookingDate.value;
      var pluginShortTermBookingTimeValue = pluginShortTermBookingTime.value;

      if (pluginShortTermBookingDateValue.length > 0 || pluginShortTermBookingTimeValue.length > 0) {
        if (defaultLabel) defaultLabel.classList.add("d-none");

        moment.locale("fr");

        if (pluginShortTermBookingDateValue.length > 0)
          pluginShortTermBookingDateValue = moment(pluginShortTermBookingDateValue, "YYYY-MM-DD").format("L");

        if (pluginShortTermBookingTimeValue.length > 0)
          pluginShortTermBookingTimeValue = moment(pluginShortTermBookingTimeValue, "h:mm").format("LT");

        var selectedLabel = document.createElement("span");
        selectedLabel.className = "text-navy0 btn-short-term-booking-selected-label";
        selectedLabel.textContent = `${[pluginShortTermBookingDateValue, pluginShortTermBookingTimeValue].filter((i) => !(typeof i === "undefined" || i === null || i == "")).join(" ")}`;
        pluginShortTermBookingBtn.appendChild(selectedLabel);

        this.displayNextInput();
      } else {
        defaultLabel.classList.remove("d-none");
      }
    }
  }

  vehicleTypeChanged(e) {
    const pluginYourVehicleBtn = document.querySelector("button[data-display-plugin-yp='#pluginYourVehicle']");
    if (pluginYourVehicleBtn) {
      const defaultIcon = pluginYourVehicleBtn.querySelector(".btn-vehicle-default-icon");
      const defaultLabel = pluginYourVehicleBtn.querySelector(".btn-vehicle-default-label");

      pluginYourVehicleBtn.querySelectorAll(".btn-vehicle-selected-icon").forEach((elem) => elem.remove());
      pluginYourVehicleBtn.querySelectorAll(".btn-vehicle-selected-label").forEach((elem) => elem.remove());

      if (e == null) {
        defaultIcon.classList.remove("d-none");
        defaultLabel.classList.remove("d-none");
      }

      if (e && e.currentTarget && e.currentTarget.value) {
        if (defaultIcon) defaultIcon.classList.add("d-none");
        if (defaultLabel) defaultLabel.classList.add("d-none");

        var svgElement = e.currentTarget.parentNode.querySelector("[data-js-vehicle-kind-svg] svg");
        var selectedIcon = svgElement.cloneNode(true);
        selectedIcon.classList.remove(...selectedIcon.classList);
        selectedIcon.classList.add("svg-navy1", "svg-size-24", "btn-vehicle-selected-icon");
        pluginYourVehicleBtn.appendChild(selectedIcon);

        var selectedLabel = document.createElement("span");
        selectedLabel.className = "text-navy0 btn-vehicle-selected-label";
        selectedLabel.textContent = e.currentTarget.parentNode.querySelector("[data-js-vehicle-kind-text]").innerText;
        pluginYourVehicleBtn.appendChild(selectedLabel);

        this.displayNextInput();
      } else {
        defaultIcon.classList.remove("d-none");
        defaultLabel.classList.remove("d-none");
      }
    }
  }

  triggerSearch(e) {
    if (e) e.preventDefault();
    this.hideOverlayBackground();
    this.displaySearchOverlaysLoading();
    this.manageYesparkCustomsButtonPluginsClassActive();
    this.closeAllBootstrapModals(e);
    this.closeAllYesparkCustomPluginsOpened();
    this.closeAllFlatpickrOpened();
    if (this.hasFormTarget) {
      this.formTarget.requestSubmit();
    } else {
      this.submitForm();
    }
  }

  filtersChanged() {
    this.changeFiltersCount();
  }

  changeFiltersCount() {
    let priceSlider = document.querySelector("input#price_slider");
    let checkableInputs = document.querySelectorAll(
      "#modalPluginFilters input[type='checkbox']:checked:not(#search_show_short_term)",
    );
    let availableSpotsCount = document.querySelector("input#search_available_spots_count");
    let totalSpotsCount = document.querySelector("input#search_spots_count");
    let signingStatesSelected = document.querySelector("select#search_signing_states")?.selectedOptions.length > 0;
    let ownersSelected = document.querySelector("select#search_owners")?.selectedOptions.length > 0;
    let ownerTypeSelected = document.querySelector("select#search_owner_types")?.selectedOptions.length > 0;

    this.filtersCount = 0;

    if (priceSlider && priceSlider.dataset.value && priceSlider.dataset.value != priceSlider.dataset.sliderMax)
      this.filtersCount += 1;
    if (checkableInputs && checkableInputs.length > 0) this.filtersCount += checkableInputs.length;
    if (availableSpotsCount && availableSpotsCount.value != "") this.filtersCount += 1;
    if (totalSpotsCount && totalSpotsCount.value != "") this.filtersCount += 1;
    if (signingStatesSelected) this.filtersCount += 1;
    if (ownersSelected) this.filtersCount += 1;
    if (ownerTypeSelected) this.filtersCount += 1;

    if (this.filtersCount == 0) {
      if (this.hasFiltersCounterDesktopTarget) {
        this.filtersCounterDesktopTarget.textContent = "";
      }
      if (this.hasFiltersCounterMobileTarget) {
        this.filtersCounterMobileTarget.textContent = "";
        this.filtersCounterMobileTarget.classList.add("d-none");
      }
    } else {
      if (this.hasFiltersCounterDesktopTarget) {
        this.filtersCounterDesktopTarget.textContent = ` (${this.filtersCount})`;
      }
      if (this.hasFiltersCounterMobileTarget) {
        this.filtersCounterMobileTarget.textContent = this.filtersCount;
        this.filtersCounterMobileTarget.classList.remove("d-none");
      }
    }

    this.manageYesparkCustomsButtonPluginsClassActive();
  }

  manageYesparkCustomsButtonPluginsClassActive() {
    let buttons = document.querySelectorAll("[data-display-plugin-yp]");

    buttons.forEach((button) => {
      let buttonType = button.dataset.displayPluginYp;
      let active = false;

      if (buttonType == "#modalPluginFilters") active = this.filtersCount > 0;
      else if (buttonType == "#pluginYourVehicle") active = this.pluginYourVehicleDisplaying();

      if (active) button.classList.add("active");
      else button.classList.remove("active");
    });
  }

  closeAllYesparkCustomPluginsOpened() {
    this.closeOtherYesparkCustomPluginsOpened(null);
  }

  closeAllBootstrapModals(e) {
    if (e?.currentTarget?.parentElement?.id == "sidebar-checkout-tab-menu") return;
    document.querySelectorAll(".modal").forEach((modal) => bootstrap.Modal.getInstance(modal)?.hide());
  }

  closeAllFlatpickrOpened() {
    document.querySelectorAll(".flatpickr-input").forEach((instance) => {
      if (instance._flatpickr && instance._flatpickr.isOpen) instance._flatpickr.close();
    });
  }

  closeOtherYesparkCustomPluginsOpened(activePlugin) {
    let pluginsOpened = document.querySelectorAll(".search-plugin.show");

    if (pluginsOpened) {
      pluginsOpened.forEach((plugin) => {
        if (activePlugin == null || plugin != activePlugin) plugin.classList.remove("show");
      });

      this.manageYesparkCustomsButtonPluginsClassActive();
    }
  }

  openYesparkCustomPlugin(e) {
    var button = e instanceof HTMLElement ? e : e.currentTarget == window ? e.target : e.currentTarget;
    var plugin = document.querySelector(button.dataset.displayPluginYp);
    this.closeAllFlatpickrOpened();
    if (plugin.classList.contains("show")) {
      plugin.classList.remove("show");
    } else {
      this.closeOtherYesparkCustomPluginsOpened(plugin);
      this.displayOverlayBackground();
      plugin.classList.add("show");
    }
    this.manageYesparkCustomsButtonPluginsClassActive();
  }

  hasVehiculeSizeFilled() {
    const vehicleSizeRadios = document.querySelectorAll("#pluginYourVehicle input[type='radio'][name='vehicle_size']");
    return Array.from(vehicleSizeRadios).some((radio) => radio.checked);
  }

  pluginYourVehicleDisplaying() {
    return document.querySelector("#pluginYourVehicle.show");
  }

  priceSliderChanged(slideEvt) {
    const priceSlider = document.querySelector("#modalPluginFilters input#price_slider");
    const withPriceMaxElements = document.querySelectorAll("#modalPluginFilters .with-price-max");
    const withPriceElements = document.querySelectorAll("#modalPluginFilters .with-price");
    const priceValueMaxElement = document.querySelector("#js_search_price_value_max");

    if (priceSlider.dataset.sliderMax == slideEvt.value.newValue) {
      withPriceMaxElements.forEach((element) => {
        element.classList.remove("d-none");
        element.classList.add("d-flex");
      });
      withPriceElements.forEach((element) => {
        element.classList.add("d-none");
        element.classList.remove("d-flex");
      });
    } else {
      withPriceMaxElements.forEach((element) => {
        element.classList.add("d-none");
        element.classList.remove("d-flex");
      });
      withPriceElements.forEach((element) => {
        element.classList.remove("d-none");
        element.classList.add("d-flex");
      });
    }
    priceValueMaxElement.textContent = slideEvt.value.newValue + "€";
    this.changeFiltersCount();
  }

  resetPluginFilters(e) {
    $("#modalPluginFilters input#price_slider").bootstrapSlider(
      "setValue",
      document.querySelector("#modalPluginFilters input#price_slider").getAttribute("data-slider-max"),
    );

    document.querySelector("#modalPluginFilters input#search_equipment_electric").checked = false;
    document.querySelectorAll('#modalPluginFilters input[type="checkbox"]').forEach((checkbox) => {
      checkbox.checked = false;
    });

    document.querySelectorAll("#modalPluginFilters .with-price-max").forEach((element) => {
      element.classList.remove("d-none");
      element.classList.add("d-flex");
    });

    document.querySelectorAll("#modalPluginFilters .with-price").forEach((element) => {
      element.classList.add("d-none");
      element.classList.remove("d-flex");
    });

    const availableSpotsCount = document.querySelector("#modalPluginFilters #search_available_spots_count");
    if (availableSpotsCount) {
      availableSpotsCount.value = "";
      availableSpotsCount.dispatchEvent(new Event("change"));
    }

    document.querySelector('input[type="radio"][name="vehicle_size"]').checked = false;

    this.vehicleTypeChanged(null);

    const signingTypes = document.querySelector("#search_signing_states");
    if (signingTypes) {
      $(signingTypes).selectpicker("deselectAll");
    }

    const owners = document.querySelector("#search_owners");
    if (owners) {
      $(owners).selectpicker("deselectAll");
    }

    const ownerTypes = document.querySelector("#search_owner_types");
    if (ownerTypes) {
      $(ownerTypes).selectpicker("deselectAll");
    }

    this.changeFiltersCount();
    this.triggerSearch(e);
  }

  filterRechargeEquipmentElectricChanged(e) {
    document.querySelector("#modalPluginFilters input#search_show_full").checked = e.currentTarget.checked;
    document.querySelector("#modalPluginFilters input#search_show_full").dispatchEvent(new Event("change"));
    document.querySelector("#pluginYourVehicle input#search_show_vehicle_electric").checked = e.currentTarget.checked;
  }

  electricVehicleSelected(e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    if (this.isSearchPage()) {
      document.querySelector("#modalPluginFilters #search_equipment_electric").checked = e.currentTarget.checked;
      document.querySelector("#modalPluginFilters input#search_show_full").checked = e.currentTarget.checked;
      this.filtersChanged();
      this.triggerSearch(e);
    } else {
      document.querySelector("#search-filters-and-subfilters input#equipment_electric").value = e.currentTarget.checked
        ? "on"
        : "off";
    }
  }

  orderChanged(e) {
    var input = e.currentTarget;
    if (input.dataset.currentValue != input.value) {
      var dropdownText = document.querySelector(".dropdown-filter-order-by-name-js");
      dropdownText.innerHTML = input.dataset.newLabel;
      document.querySelectorAll(".dropdown-menu-body input").forEach((dropdownInput) => {
        dropdownInput.dataset.currentValue = input.value;
      });
      this.triggerSearch(e);
    }
  }

  toggleViewMobile(e) {
    const toggleView = e.params.toggleView || e.detail.toggleView;
    if (!toggleView) return false;

    e.currentTarget.style.display = "none";

    if (toggleView == "map") {
      document.querySelector(".search-results-as-list").classList.add("module--hide");
      document.querySelector(".search-results-as-map").classList.remove("module--hide");
      e.currentTarget.nextElementSibling.removeAttribute("style");
    } else if (toggleView == "list") {
      document.querySelector(".search-results-as-map").classList.add("module--hide");
      document.querySelector(".search-results-as-list").classList.remove("module--hide");
      e.currentTarget.previousElementSibling.removeAttribute("style");
    }

    window.dispatchEvent(new CustomEvent("resize-map", { bubbles: true }));
  }
}
